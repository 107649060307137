<template>
  <div class="search-container">
    <div class="search-box">
      <el-input
        placeholder="请输入内容"
        v-model="searchVal"
        class="input-with-select"
      >
        <el-button class="search-btn" slot="append" @click="searchNews">搜索</el-button>
      </el-input>
    </div>
    <div class="search-main">
      <div class="content">
        <div class="search-results">
          <div class="list" v-for="item in searchList" :key="item.article_id">
            <h3 class="title" @click="goDetail(item.article_id)">
              <el-link :underline="false" href="#" target="_blank" :title="item.title">{{item.title}}</el-link>
            </h3>
            <div class="detail">
              <div class="ftthumb">
                <el-image :src="item.thumb" fit="cover" v-if="item.thumb"/></el-image>
                <img src="@/assets/images/default.png" v-else/>
              </div>
              <div class="rtdetails">                
                <p>{{item.content}}</p>
                <div class="data">{{item.update_time | stringData}}</div>
              </div>
            </div>
          </div>
          
        </div>
        <div class="paging" v-if="searchList.length">
          <el-pagination
            background
            :current-page="page"
            @current-change="searchNews"
            layout="prev, pager, next"
            :hide-on-single-page="true"
            :page-count="totalPage"
          >
          </el-pagination>
        </div>
        <el-empty description="暂无数据" v-if="!searchList.length"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  filters: {
    stringData: function(date) {
      return moment(date * 1000).format("YYYY-MM-DD");
    },
  },
  data() {
    return {
      searchVal: this.$route.query.searchVal,
      page: 1,
      totalPage: 0,
      searchList:[],
    };
  },
  created() {
    this.searchNews(this.page);
  },
  methods: {
    searchNews(page) {
      this.$http
        .get(
          `/api/web/news/searchNews?page=${page}&title=${this.searchVal}`
        )
        .then((res) => {
          this.searchList = res.data.list;
          this.totalPage = res.data.totalPage;
        });
    },
    goDetail(id){
      let routeUrl = this.$router.resolve({
        name:'NewsDetail',
        query:{id:id}
      });
      window.open(routeUrl.href, '_blank');
    },
    
  },
};
</script>

<style lang='less' scoped>
.search-container {
  padding: 30px 0 0;
  background: #f5f5f5;
  /deep/.search-box {
    width: 1000px;
    margin: 0 auto;
    .el-input__inner {
      height: 50px;
      border: 1px solid #f5f5f5;
    }
    .el-input-group__append {
      padding: 0;
    }
    .search-btn {
      float: right;
      width: 120px;
      border: none;
      height: 50px;
      line-height: 50px;
      color: #fff;
      text-align: center;
      margin: 0;
      padding: 0;
      background-color: #d71a18;
    }
  }
  .search-main {
    width: 100%;
    background-color: #fff;
    margin-top: 30px;
    padding: 30px 0;
    min-height: calc(100vh - 520px);
    .content {
      width: 1000px;
      margin: 0 auto;
      .list {
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
        .title {
          font-size: 16px;
          line-height: 28px;
          font-weight: 600;
          margin-bottom: 10px;
          margin-top: 0;
          .el-link {
            font-size: 16px;
            font-weight: 600;
          }
        }
        .detail {
          display: flex;
          justify-content:flex-start;
          .ftthumb {
            width: 140px;   
            max-height: 100px;        
            margin-right: 15px;
            overflow: hidden;
            img {
              display: block;
              width: 100%;
              min-height: 90px;
            }
          }
          .rtdetails{
            width: calc(100% - 155px);
            .data{
              font-size: 14px;
              line-height: 28px;
              color: #9195A3;
              margin-right: 30px;
            }
            p{
              font-size: 14px;
              line-height: 24px;
              color: #666;
              overflow: hidden;
              margin-top: 0;
              margin-bottom: 0;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
  .paging {
    width: 100%;
    margin-top: 20px;

    /deep/.el-pager li {
      font-size: 16px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-weight: normal;
      background-color: white;
    }
    /deep/.el-pager li:not(.disabled).active {
      background-color: #d71a18;
      color: #fff;
    }
    /deep/.btn-next,
    /deep/.btn-prev {
      background-color: white;
      font-size: 16px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-weight: normal;
    }
  }
}
</style>
